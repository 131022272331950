
import { Component, Prop, Vue } from "vue-property-decorator";

declare type IWorkingCategory = {
  active: number;
  commercialWarrantyDay: number;
  commonPrice: number;
  consumerWarrantyDay: number;
  description: string;
  id: string;
  maxPrice: number;
  minPrice: number;
  name: string;
  priceRangeDescription: string;
  skillItemId: string;
  warrantyDescription: string;
  createBy: string;
  createDate: string;
  updateBy?: string;
  updateDate?: string;  
};

@Component({})
export default class ServiceSearchKeyword extends Vue {
  @Prop() public keywordData!: any;
  //data
  workingCategory = {};

  //methods
  getSearchData(index:number): void {
    this.workingCategory = this.keywordData[index];
    this.$emit("getSearchData", this.workingCategory);
  }
}
