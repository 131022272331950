
import { Component, Prop, Vue } from "vue-property-decorator";
import ServiceL3 from "@/components/Service/ServiceL3.vue";

declare type IWorkingCategory = {
  active: number;
  commercialWarrantyDay: number;
  commonPrice: number;
  consumerWarrantyDay: number;
  description: string;
  id: string;
  maxPrice: number;
  minPrice: number;
  name: string;
  priceRangeDescription: string;
  skillItemId: string;
  warrantyDescription: string;
  createBy: string;
  createDate: string;
  updateBy?: string;
  updateDate?: string;  
};

@Component({
  components: {
    ServiceL3,
  },
})

export default class ServiceRow extends Vue {
  // @Prop() private chooseLevelThree!: number;
  @Prop() public item2!: any;

  //methods
  getWorkingCategoryData(data:IWorkingCategory): void {
    this.$emit("sendWorkingCategoryData", data);
  }
}
