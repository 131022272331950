
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import Loading from "@/components/Utility/Loading.vue";
import ServiceRow from "@/components/Service/ServiceRow.vue";
import ServiceModal from "@/components/Service/ServiceModal.vue";
import ServiceSearchKeyword from "@/components/Service/ServiceSearchKeyword.vue";
import tigermaster from "fdtigermaster-client-sdk";

declare type workingCategoryItem = {
  L3: string[];
  desc: string;
  imagePath: string;
  name: string;  
}

declare type workingCategory = {
  L2: workingCategoryItem[];
  desc: string;
  name: string;
}

@Component({
  components: {
    // Navbar,
    // Footer,
    Loading,
    ServiceRow,
    ServiceModal,
    ServiceSearchKeyword,
  },
})


export default class Service extends Vue {
  //data
  inputKeyword = "";
  workingCategoryList:workingCategory[] = [];
  compositionStatus = false; //鍵盤打字
  activeCategoryIndex!: number; //tab點到的項目的index
  chooseLevelOne = 0;
  chooseLevelThree = 0; //null
  workingCategoryData = {};
  isLoading = true;
  showServiceRow = true;
  showKeyword = false;
  searchResult: {
    active:number;
    commercialWarrantyDay:number;
    commonPrice:number;
    consumerWarrantyDay:number;
    createBy:string;
    createDate:string;
    description:string;
    id:string;
    maxPrice:number;
    minPrice:number;
    name: string;
    priceRangeDescription:string;
    skillItemId:string;
    warrantyDescription:string;    
  }[] = [];
  target!: HTMLElement;

  //hook
  created(): void {
    this.getData();
    if (this.$route.query.value) {
      this.activeCategoryIndex = this.$route.query.value as unknown as number;
      this.chooseLevelOne = this.$route.query.value as unknown as number;
    } else {
      this.activeCategoryIndex = 0;
      this.chooseLevelOne = 0;
    }
  }

  //methods
  clearContent(e: any): void {
    e.target.placeholder = "";
    this.inputKeyword = "";
    this.showServiceRow = true;
  }
  getIndex(index:number): void {
    this.chooseLevelOne = index;
    
    this.activeCategoryIndex = index;

    this.inputKeyword = "";
    this.showServiceRow = true;
    this.showKeyword = false;
  }
  async getData(): Promise<void> {
    this.workingCategoryList = await tigermaster.services.Level.download();
    this.isLoading = false;
  }
  getWorkingCategoryData(data: { id: string }): void {
    this.workingCategoryData = data;
    this.$bvModal.show("service-info-modal");
  }
  async onSearchClick(): Promise<void> {
    if (this.compositionStatus) return;
    this.activeCategoryIndex = -1;

    //const database = new Database();
    const query = tigermaster.database.query("working_category");
    const searchArray = Object.entries(this.inputKeyword);

    searchArray.forEach((ele: any) => {
      ele[2] = "LIKE";
      ele[1] = "%" + ele[1] + "%";
      query.where("working_category.name", ele[2], ele[1]);
    });

    try {
      const res = await query.get();
      this.searchResult = res.data;
    } catch{
      alert("查無此項目");
    }
    this.showServiceRow = false;
    this.showKeyword = true;
  }

  //computed
  get levelOneList(): string[] {
    return this.workingCategoryList.map((item: { name: string }) => {
      return item.name;
    });
  }
  get L2(): workingCategoryItem[] {
    return this.chooseLevelOne !== null? this.workingCategoryList[this.chooseLevelOne].L2 : [];
  }
}
