
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class ServiceModal extends Vue {
  @Prop() public workingCategoryData: {
    id: string;
    name: string;
    description: string;
    commonPrice: number;
    minPrice: number;
    maxPrice: number;
    priceRangeDescription: string;
    consumerWarrantyDay: number;
    commercialWarrantyDay: number;
    warrantyDescription: string
  };
  @Prop() private chooseLevelOne!: string;
  // @Prop() private money!: any;

  //data
  showModal = false;
  barWidth = 307;
  priceWidth = 71;

  // methods
  addCommaToPrice(data: number): string {
    return data? data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
  }  

  //computed
  get autoMargin(): string {
    let percent =
      (this.workingCategoryData.commonPrice - this.workingCategoryData.minPrice) /
      (this.workingCategoryData.maxPrice - this.workingCategoryData.minPrice + 1);
    let margin = (this.barWidth - this.priceWidth) * percent;
    return margin + "px";
  }
  get image(): string {
    return `${process.env.VUE_APP_MODEL_URL}/file/working_category%2f${this.workingCategoryData.id}.jpg`;
  }
}
