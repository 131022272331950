
import { Component, Prop, Vue } from "vue-property-decorator";
import Loading from "@/components/Utility/Loading.vue";
import tigermaster from "fdtigermaster-client-sdk";
@Component({
  components: {
    Loading,
  },
})
export default class ServiceL3 extends Vue {
  @Prop() private item3!: string;

  //data
  workingCategory = {
    name: ''
  };
  isLoading = true;

  //hook
  created(): void {
    this.getIdInfo();
  }

  //methods
  async getIdInfo(): Promise<void> {
    const res = await tigermaster.database
      .query("working_category")
      .where("working_category.id", "=", this.item3)
      .get();
    this.workingCategory = res.data[0];
    this.isLoading = false;
  }
  selectL3(): void {
    this.$emit("sendWorkingCategoryData", this.workingCategory);
  }
}
